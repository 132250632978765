<template>
  <div class="login">
    <v-container class="my-6">
      <v-row class="text-center">
        <v-col>
          <h1>Bienvenid@ a Renase!</h1>
        </v-col>
      </v-row>
      <v-form
        v-model="formProps.valid"
        ref="form"
        lazy-validation
        v-on:submit.prevent
      >
        <v-row class="justify-center">
          <v-col cols="6" md="4">
            <v-text-field
              label="Email"
              v-model="formData.email"
              :rules="formProps.emailRules"
              name="email"
              id="email"
              autocomplete="email"
              required
              ref="email"
            ></v-text-field>
            <v-text-field
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              label="Clave"
              v-model="formData.password"
              :rules="formProps.passRules"
              :type="show ? 'text' : 'password'"
              name="password"
              id="password"
              autocomplete="password"
              required
              ref="password"
              @click:append="show = !show"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="justify-center my-5">
          <v-btn
            :loading="loading"
            :disabled="!formProps.valid || loading"
            @click="submit"
            color="primary"
            large
            type="submit"
          >
            Ingresar
            <template v-slot:loader>
              <span color="info">Iniciando...</span>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data: () => ({
    show: false,
    formData: {
      email: '',
      password: ''
    },
    formProps: {
      valid: true,
      emailRules: [
        v => !!v || 'Ingrese su correo',
        v => /.+@.+\..+/.test(v) || 'Escriba un correo válido'
      ],

      passRules: [
        v => !!v || 'Ingrese su contraseña',
        v => (v && v.length >= 8) || 'La clave debe tener al menos 8 caracteres'
      ]
    },
    loading: false
  }),
  mounted: function() {
    this.$refs.email.$data.isFocused = true
    this.$refs.password.$data.isFocused = true
  },

  methods: {
    submit: async function() {
      if (this.$refs.form.validate()) {
        this.loading = true
        const login_result = await this.$store.dispatch('login', this.formData)
        this.loading = false
        if (login_result) return this.$router.push('home')
      }
    }
  }
}
</script>
